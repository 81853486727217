@use "./src/scss/global.scss" as *;

.presale-utils {
  padding-top: 77px;
  padding-bottom: 80px;

  hr {
    margin-bottom: 80px;
    opacity: 0.15;
  }
}
