@use "./src/scss/global.scss" as *;
@use "../TransactionStepper.scss" as *;
@use "../TransactionStep/Step.scss" as *;

.divider {
  position: absolute;
  left: 50%;
  top: calc($step-number-height / 2);
  width: 190px;
  height: 2px;
  background-color: $primary;
  opacity: 0.2;
  border: none;
  transform: translate(-50%, 0%);

  &.active {
    opacity: 1;
  }
}
