@use "./src/scss/global.scss" as *;

.presale-card {
  .wallet-feedback {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: max-content;
    width: max-content;
    max-width: 287px;
    text-align: center;
    z-index: 1;

    .spinner {
      width: 30px;
      height: 30px;
    }
  }
  &.primary {
    .wallet-feedback {
      bottom: 110px;
    }
  }

  &.secondary {
    .wallet-feedback {
      bottom: 79px;
    }
  }
}
