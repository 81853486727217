@use "./src/scss/global.scss" as *;

.fetching-error-modal {
  padding: 149px 0px;
  text-align: center;
  position: relative;
  width: 790px;

  .title {
    font-size: 28px;
    max-width: 510px;
  }

  .top-right-corner {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 25px;
    width: max-content;
    height: max-content;
  }

  .warning-text {
    max-width: 400px;
    line-height: 1.6;
  }
}
