@use "@scss/global.scss" as *;

.something-went-wrong-modal {
  .stack {
    width: 100%;
    height: 100%;
  }

  .modal-content-wrapper {
    padding: 40px 150px;
    text-align: center;
    min-width: 790px;

    .title {
      margin-bottom: 26px;
    }
    .description {
      max-width: 409px;
    }

    .input {
      width: 100%;
    }

    .text-area {
      width: 100%;

      textarea {
        min-height: 100px;
        font-size: 16px;
      }
    }
  }

  &-close-button {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 25px;
    cursor: pointer;
    width: max-content;
  }
}
