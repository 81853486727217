@use "@scss/global.scss" as *;

.textarea {
  width: 100%;
  font-size: 16px;
  text-align: start;
  position: relative;

  textarea {
    height: 100%;
    border-radius: 4px;
    font-size: 14px;
    font-weight: $light;
    line-height: 1.6;
    color: $white-1;
    padding: 16px;
    letter-spacing: 0.2px;
    border: 1px solid transparent;
    background: rgba($white-3, 30%);
    width: 100%;
    caret-color: $primary;
    transition: all 0.2s;
    outline: none;
    font-family: "Poppins", sans-serif;

    &:hover {
      border-color: $primary;
    }

    &::placeholder {
      font-weight: $light;
    }

    &:not(:placeholder-shown) {
      color: $white;
    }

    &.dark-background {
      background: rgba($black, 50%);
    }

    &:focus {
      border-color: $primary;
      background: $black;
      @include input-outline($primary);
    }

    &.danger-border {
      border: 1px solid $danger;
      &:focus {
        @include input-outline($danger);
      }
    }

    &.danger {
      color: $danger;
      caret-color: $danger;
    }
  }

  .label {
    font-size: 14px;
    font-weight: $light;
    position: relative;
    bottom: 12px;
    line-height: 1.6;
    color: $white-2;
  }

  .error {
    position: absolute;
    bottom: -27px;
    margin-left: 16px;
    font-size: 12px;
    font-weight: $light;
    line-height: 1.5;
    letter-spacing: 0.37px;
    color: $danger;
  }
}
