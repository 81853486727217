@use "./src/scss/global.scss" as *;

.vesting-popup {
  top: -100%;
  left: 50%;
  transform: translate(-50%, 50px);
  position: absolute;
  width: max-content;
  max-width: 290px;
  height: max-content;
  background-color: $white-0;
  border-radius: 8px;
  padding: 24px 20px;
  transition: opacity 0.1s ease-in-out;

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 49%;
    transform: rotate(45deg) translate(-50%, -50%);
    border: 4px solid $white-0;
  }

  ul {
    li {
      font-size: 12px;
      color: $black;
      text-align: start;
    }
  }
}
