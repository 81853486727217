@use "@scss/variables.scss" as *;

.motion {
  width: max-content;
  height: max-content;
  z-index: 1;
}

$duration0: 0.2s;
$duration1: 0.3s;
$duration2: 0.6s;
$duration3: 1s;
$duration4: 1.5s;
$duration5: 3s;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin-0 {
  animation: spin $duration0 linear infinite;
}
.spin-1 {
  animation: spin $duration1 linear infinite;
}
.spin-2 {
  animation: spin $duration2 linear infinite;
}
.spin-3 {
  animation: spin $duration3 linear infinite;
}
.spin-4 {
  animation: spin $duration4 linear infinite;
}
.spin-5 {
  animation: spin $duration5 linear infinite;
}

.spin-0,
.spin-1,
.spin-2,
.spin-3,
.spin-4,
.spin-5 {
  animation-play-state: running;
  &.paused {
    animation-play-state: paused;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(4px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-8px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(8px, 0, 0);
  }
}

.shake-0 {
  animation: shake $duration0 linear forwards;
}

.shake-1 {
  animation: shake $duration1 linear forwards;
}

.shake-2 {
  animation: shake $duration2 linear forwards;
}

.shake-3 {
  animation: shake $duration3 linear forwards;
}

.shake-4 {
  animation: shake $duration4 linear forwards;
}

.shake-5 {
  animation: shake $duration5 linear forwards;
}

.shake-0,
.shake-1,
.shake-2,
.shake-3,
.shake-4,
.shake-5 {
  animation-play-state: running;

  &.paused {
    animation-play-state: paused;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.appear-0 {
  animation: appear $duration0 ease-out forwards;
}
.appear-1 {
  animation: appear $duration1 ease-out forwards;
}
.appear-2 {
  animation: appear $duration2 ease-out forwards;
}
.appear-3 {
  animation: appear $duration3 ease-out forwards;
}
.appear-4 {
  animation: appear $duration4 ease-out forwards;
}
.appear-5 {
  animation: appear $duration5 ease-out forwards;
}

@keyframes slide-left {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes slide-right {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes slide-top {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes slide-bottom {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}

.slide {
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  &-fromLeft {
    animation-name: slide-left;
  }

  &-fromRight {
    animation-name: slide-right;
  }

  &-fromTop {
    animation-name: slide-top;
  }

  &-fromBottom {
    animation-name: slide-bottom;
  }

  &-fromLeft,
  &-fromRight,
  &-fromTop,
  &-fromBottom {
    &-0 {
      animation-duration: $duration0;
    }
    &-1 {
      animation-duration: $duration1;
    }
    &-2 {
      animation-duration: $duration2;
    }
    &-3 {
      animation-duration: $duration3;
    }
    &-4 {
      animation-duration: $duration4;
    }
    &-5 {
      animation-duration: $duration5;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.99);
    box-shadow: 0 0 0px 0 $primary;
  }

  30% {
    transform: scale(1);
    box-shadow: 0 0 30px 20px rgba(231, 165, 37, 0);
  }

  31% {
    box-shadow: 0 0 0px 0px rgba(231, 165, 37, 0);
  }

  100% {
    transform: scale(0.99);
    box-shadow: 0 0 0px 0 rgba(231, 165, 37, 0);
  }
}

.pulse {
  width: max-content;
  height: max-content;
  border-radius: 4px;
  backdrop-filter: blur(20px);
  will-change: transform, box-shadow;

  &-0 {
    animation: pulse $duration0 ease-out infinite;
  }
  &-1 {
    animation: pulse $duration1 ease-out infinite;
  }
  &-2 {
    animation: pulse $duration2 ease-out infinite;
  }
  &-3 {
    animation: pulse $duration3 ease-out infinite;
  }
  &-4 {
    animation: pulse $duration4 ease-out infinite;
  }
  &-5 {
    animation: pulse $duration5 ease-out infinite;
  }
}
