.debug-page {
  padding-top: 50px;

  img {
    margin-left: 20%;
    height: 200px;
    margin-bottom: 50px;
  }

  button {
    color: lightblue !important;
    width: max-content;
    margin: 0 30px;
  }

  .actions {
    margin-left: 10%;
    width: 80%;
    max-width: 1200px;
  }

  input {
    margin-left: 30px;
  }

  .actions td {
    border-bottom: 1px solid #ccc;
    color: white;
    height: 70px;
  }
}