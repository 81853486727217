@use "@scss/global.scss" as *;

.network{
  width: max-content;
  height: max-content;
  border-radius: 12px;
  background-color: $white-3;
  padding: 3px  8px;
}

.contentBinance{
  font-size: 12px;
  line-height: 150%;
  font-weight: $light;

}
