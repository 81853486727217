@use "@scss/global.scss" as *;

.validating {
  .stack {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  padding: 100px 240px;

  .spinner {
    height: 100%;
    width: 100%;
  }

  .title {
    width: max-content;
  }
}
