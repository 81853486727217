@use "@scss/global.scss" as *;
@use "@scss/variables.scss" as *;

.layout {
  min-height: 100vh;
  height: max-content;
  width: $xl;
  margin: auto;
  position: relative;
  padding: 0px 15px;

  &.navbar-content-footer {
    display: block;

    .navbar-part {
      width: max-content;
      height: max-content;
    }

    .content-part {
      width: 100%;
      height: max-content;
    }

    .footer-part {
      width: 100%;
      height: max-content;
    }
  }
  &.navbar-content {
    display: block;

    .navbar-part {
      width: max-content;
      height: max-content;
    }

    .content-part {
      width: 100%;
      height: max-content;
    }
  }
  &.navbar-sidebar-content {
    display: flex;
    flex-direction: column;

    .navbar-part {
      width: 100%;
      height: 100%;
      max-height: 60px;
    }

    .bottom-part {
      flex: 1;
      display: flex;
    }

    .sidebar-part {
      height: 100%;
      width: 100%;
      max-width: 256px;
    }

    .content-part {
      flex: 1;
    }
  }
  &.sidebar-navbar-content {
    display: flex;
    flex-direction: row;

    .sidebar-part {
      height: 100%;
      width: 100%;
      max-width: 256px;
    }

    .right-part {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .navbar-part {
      width: 100%;
      height: 100%;
      max-height: 60px;
    }

    .content-part {
      flex: 1;
    }
  }

  .content-part {
    // padding: 0px 15px;
  }
}
