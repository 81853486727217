@use "./src/scss/global.scss" as *;

.sale-stage {
  position: relative;
  max-width: 350px;
  height: 20px;

  &-title {
    position: absolute;
    top: -42px;
    left: 0;
    font-size: 16px;
    font-weight: $light;
    line-height: 1;
    letter-spacing: 0.03em;
  }

  &-bar {
    display: flex;
    padding-left: 2px;
    column-gap: 4px;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    border-radius: 33px;

    .bar-status {
      font-size: 12px;
      line-height: 1.2;
      font-weight: $light;
      min-width: 110px;
      text-align: left;
    }
  }

  &-info {
    position: absolute;
    top: 40px;
    left: 0;
    font-size: 12px;
    line-height: 1.6;
    text-align: left;
    max-width: 230px;

    .subtitle {
      font-weight: $bold;
      margin-bottom: 4px;
    }

    .description {
      font-weight: $light;
    }
  }
}

.sale-stage-inactive {
  .sale-stage-bar {
    background: rgba(141, 141, 139, 0.2);
  }
}

.sale-stage-active {
  .sale-stage-bar {
    background: $primary;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.sale-stage-done {
  .sale-stage-bar {
    background: $success;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
