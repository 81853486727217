@use "./src/scss/global.scss" as *;

.navbar {
  position: fixed;
  background-color: rgba(27, 26, 23, 0.4);
  backdrop-filter: blur(40.7742px);
  left: 0px;
  top: 0px;
  width: calc(100vw - 16px);
  height: 48px;
  z-index: 10;
}
