@use "./src/scss/global.scss" as *;

.invested-card {
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 100%);
  width: 344px;
  background: rgba(50, 49, 47, 0.5);
  box-shadow: $shadow-2;
  border-radius: 0 0 12px 12px;
  height: 191px;

  &-content {
    padding: 31px 21px;

    .invested-currency {
      font-size: 16px;
      line-height: 1.4;
    }
  }
}
