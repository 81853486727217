@use "./src/scss/global.scss" as *;

$step-number-height: 32px;
$step-number-width: 32px;

.transaction-stepper {
  position: relative;
  align-items: flex-start !important;
  height: max-content;
}
