.tx-list-admin {
  color: white;
  margin-bottom: 100px;

}

.tx-list-admin a {
  color: white;
  margin-bottom: 100px;
  font-size: 14px;
}

.tx-list-admin td, .tx-list-admin th {
  border: 1px solid #ccc;
  padding: 10px;
}
