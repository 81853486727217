@use "@scss/global.scss" as *;
.group-inner-nav {
  width: 100%;
  max-width: 1110px;
  height: 100%;
  display: flex;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 0px 15px;

  button {
    width: max-content;
  }

  .group {
    width: max-content;
  }
}
