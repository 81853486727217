@use "./src/scss/global.scss" as *;

.discount-percent {
  position: absolute;
  right: -3px;
  top: -2px;

  .triangle {
    border-radius: 0px 8px 0px 0px;
    position: absolute;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    right: 0px;
    top: 0px;
    background: $gradient-7;
  }

  &.primary {
    .triangle {
      width: 98px;
      height: 98px;
    }

    .values {
      h6 {
        font-size: 22px;
      }
    }
  }
  &.secondary {
    .triangle {
      width: 78px;
      height: 78px;
    }

    .values {
      h5 {
        font-size: 25px;
      }

      h6 {
        font-size: 17.5px;
      }
    }
  }

  .values {
    position: absolute;
    right: 16px;
    top: 16px;
    * {
      line-height: 1;
    }

    h6 {
      font-weight: $regular;
    }
  }
}
