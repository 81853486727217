@use "@scss/global.scss" as *;

.switch-network-modal {
  padding-top: 92px;
  padding-bottom: 120px;
  width: 790px;

  * {
    text-align: center;
  }

  .stack {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .network-name {
    font-size: 16px;
  }
  .additional {
    max-width: 440px;
  }

  .link {
    color: $link;
    margin: 0px 4px;
  }

  .clickable {
    display: flex;
    color: $primary;
  }

  .title {
    font-size: 28px;
    max-width: 500px;
  }

  .spinner {
    width: 20px;
    height: 20px;
  }

  .close-icon {
    position: absolute;
    height: max-content;
    width: max-content;
    right: 0px;
    top: 0px;
    padding: 25px;
  }
}
