@use "./src/scss/global.scss" as *;

.footer-actions {
  .footer-action {
    height: max-content;
    text-align: unset;
    .image {
    }
    .title {
    }
    .description {
      opacity: 0.8;
      min-height: 55px;
    }

    .button {
      padding: 16px 0px;
      width: max-content;
      text-transform: uppercase;
      font-size: 14px;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $primary;
      }

      &:disabled {
        opacity: 0.24;
      }
    }
  }
}
