@use "./src/scss/global.scss" as *;
@use "../TransactionStepper.scss" as *;

$step-width: 252px;

.transaction-step {
  opacity: 0.2;
  width: $step-width;
  transition: opacity 0.3s linear;
  height: max-content;

  * {
    text-align: center;
  }

  .step-number {
    width: $step-number-width;
    height: $step-number-height;
    background-color: $primary;
    color: $black;
    border-radius: 4px;
    justify-content: center !important;
    align-items: center !important;
  }

  .step-title {
    width: 156px;
    font-size: 22px;
  }

  .description {
    width: 210px;
  }

  &.active {
    opacity: 1;
  }

  .max-content {
    width: 20px;
    height: 20px;
  }
}
