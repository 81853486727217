@use "./src/scss/global.scss" as *;

.golden-card {
  position: relative;
  width: 380px;
  background: $gradient-5;
  box-shadow: $shadow-2;
  border-radius: 12px;
  height: 236px;

  &-content {
    padding: 31px 39px 28px 20px;

    .token-name {
      line-height: 1.6;
      letter-spacing: 0.6px;
    }

    .received-balance {
      font-size: 48px;
      letter-spacing: -2px;
    }

    .received-currency {
      font-size: 24px;
    }
  }
}
