@use "./variables.scss" as *;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

hr,
a,
button,
div,
span,
label,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
}

h1 {
  font-size: 72px;
  font-weight: $bold;
  line-height: 1.6;
  letter-spacing: 0.18px;
}

h2 {
  font-size: 64px;
  font-weight: $bold;
  line-height: 1.3;
}

h3 {
  font-size: 56px;
  font-weight: $bold;
  line-height: 1.6;
}

h4 {
  font-size: 36px;
  font-weight: $bold;
  line-height: 1.6;
}

h5 {
  font-size: 32px;
  font-weight: $bold;
  line-height: 1.4;
}

h6 {
  font-size: 24px;
  font-weight: $bold;
  line-height: 1.4;
}

.heading {
  font-size: 14px;
  font-weight: $medium;
  line-height: 1.6;
}

.p0 {
  font-size: 16px;
  font-weight: $light;
  line-height: 1;
  letter-spacing: 0.03em;
}

.p1 {
  font-size: 14px;
  font-weight: $light;
  line-height: 1.6;
}

.p2 {
  font-size: 12px;
  font-weight: $light;
  line-height: 1.5;
  letter-spacing: 0.37px;
}

.p3 {
  font-size: 11px;
  font-weight: $light;
  line-height: 1.5;
  letter-spacing: 0.37px;
}

.subheader {
  font-size: 1rem;
  opacity: 0.6;
  font-weight: $bold;
}

button {
  font-weight: $regular;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

a {
  font-weight: $light;
  font-size: 12px;
  line-height: 1;
  // text-transform: uppercase;
  letter-spacing: 0.03em;
}

.link {
  color: $link;
}

.uppercase {
  text-transform: uppercase;
}

.light {
  font-weight: $light;
}

.regular {
  font-weight: $regular;
}

.medium {
  font-weight: $medium;
}

.bold {
  font-weight: $bold;
}

input {
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: 0.6px;
  font-weight: $light;
  border: none;
  outline: none;
}

.primary {
  color: $primary;
}

.bg-primary {
  background-color: $primary;
}

.white {
  color: $white;
}

.bg-white {
  background-color: $white;
}

.white-0 {
  color: $white-0;
}

.bg-white-0 {
  background-color: $white-0;
}

.white-1 {
  color: $white-1;
}

.bg-white-1 {
  background-color: $white-1;
}

.white-2 {
  color: $white-2;
}

.bg-white-2 {
  background-color: $white-2;
}

.white-3 {
  color: $white-3;
}

.bg-white-3 {
  background-color: $white-3;
}

.white-4 {
  color: $white-4;
}

.bg-white-4 {
  background-color: $white-4;
}

.white-5 {
  color: $white-5;
}

.bg-white-5 {
  background-color: $white-5;
}

.white-6 {
  color: $white-6;
}

.bg-white-6 {
  background-color: $white-6;
}

.black {
  color: $black;
}

.bg-black {
  background-color: $black;
}
