@use "./src/scss/global.scss" as *;

.presale-cards {
  padding-top: 208px;
  position: relative;
  z-index: 3;

  .flare {
    position: absolute;
    left: -2200px;
    top: 50%;
    transform: translateY(-50%);
  }
}
