@use "./src/scss/global.scss" as *;

.offline-network-modal {
  padding: 160px 220px;
  text-align: center;

  .title {
    font-size: 28px;
    margin-bottom: 12px;
  }
}
