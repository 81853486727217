@use "./src/scss/global.scss" as *;

.modal {
  max-width: 100%;
  padding: 0px 15px;
}

.unavailable-on-mobile {
  background: $gradient-1;
  padding: 60px 20px;
  border-radius: 12px;

  * {
    max-width: 100%;
  }
}
