@use "./src/scss/global.scss" as *;

$button-height: 32px;

.switch-network {
  height: $button-height;
  width: max-content;
  background-color: $black;
  border-radius: calc($button-height / 2);
  padding: 0px 16px;
  color: $white;
  text-transform: unset;
  font-size: 12px;
  letter-spacing: 0.374px;
  max-width: unset;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  &:hover:not(.connecting) {
    border: 1px solid $primary;
    color: $primary;
  }

  &.connecting {
    color: $primary;
    padding-right: 6px;
    pointer-events: none;
  }

  img {
    height: 20px;
    width: auto;
  }

  .spinner {
    height: 20px;
    width: 20px;
  }
}
