@use "./src/scss/global.scss" as *;

.cell {
  width: 96px;
  // width: auto;
  padding: 8px 0px;
  padding-left: 8px;
  text-align: left;

  &.longer {
    width: 150px;
  }

  .underline {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: underline;
  }
}
