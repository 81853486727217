@use "@scss/global.scss" as *;

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23px 0px;
  max-height: 60px;
  transition: all 0.2s;
  font-size: 16px;

  &.primary:not(:disabled) {
    background: $primary;
    @include text-scaffold(16px, $regular, $white-3, 14px, 0.03em);

    &:hover {
      background: $primary-hover;
    }
  }
  &:disabled {
    background: $black-2;
    opacity: 0.24;
    color: #dbdada !important;
    cursor: default;
  }

  &.secondary {
    background-color: $white-2;
    color: $black;

    &:hover {
      background-color: $white-2-hover;
    }
  }

  .sizes {
    width: 20px;
    height: 20px;
  }
}
