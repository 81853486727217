@use "@scss/global.scss" as *;

.modal {
  height: max-content;
  width: max-content;
  height: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
