@use "@scss/variables.scss" as *;

* {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  box-sizing: border-box;
}

html {
  min-height: 100vh;
  height: max-content;
  display: flex;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
  width: 100%;
  min-width: max-content;
  font-size: 16px;
  color: $black;
  background-color: $white;
  font-family: "Poppins", sans-serif !important;
}

#root {
  overflow-x: hidden;
}

#root,
section {
  min-height: 100%;
  height: max-content;
  width: 100%;
}

body {
  position: relative;
  background-color: $black;
  flex: 1;
}

section {
  position: relative;
  overflow-x: hidden;
}

hr {
  margin: 0px;
  padding: 0px;
}

a {
  color: unset;
  text-decoration: none;
}

button {
  border: none;
  background-color: transparent;
}

#overlay {
  position: absolute;
  height: 100vh;
  // width: 100vw;
  left: 0px;
  top: 0px;
  z-index: 1000;

  .alert {
    z-index: 6001;
  }
  .notification {
    z-index: 5001;
  }
  .message {
    z-index: 4001;
  }
  .modal {
    z-index: 3001;
  }
  .dialog {
    z-index: 2001;
  }
  .drawer {
    z-index: 1001;
  }
}
