@use "./src/scss/global.scss" as *;

.table-row {
  width: 100%;
  height: max-content;
  min-height: 49px;
  color: $white-2;
  border-radius: 12px;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;

  &.new {
    color: $primary;
    background-color: rgba($white-6, 2%);
    border: 1px solid rgba($white-6, 10%);
  }

  a {
    text-transform: none;
  }
}
