@use "@scss/global.scss" as *;

.notification {
  width: 360px;
  min-height: 100px;
  height: max-content;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 4px;
  padding: 15px;
  animation: 0.3s slide-from-right ease-in-out;

  .stack {
    text-align: left;
  }

  &-success {
    border: 1px solid $success;
    background: darken($success, 20%);
  }
  &-error {
    border: 1px solid $danger;
    background: darken($danger, 35%);
  }

  &-close-button {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 15px;
    width: max-content;
    cursor: pointer;

    img {
      width: 12px;
      height: 12px;
    }

    &:hover {
      filter: brightness(0.8);
    }
  }

  @keyframes slide-from-right {
    from {
      right: -360px;
      opacity: 0;
    }
    to {
      right: 20px;
      opacity: 1;
    }
  }
}
