@use "./src/scss/global.scss" as *;

.two-step-transaction {
  padding: 70px 0px;
  padding-bottom: 88px;
  width: 790px;

  .top-right-corner {
    position: absolute;
    right: 0px;
    top: 0px;
    height: max-content;
    width: max-content;

    button {
      padding: 20px;
    }
  }

  .bought-tokens-info {
    width: max-content;
    height: max-content;
    background: #1b1a17;
    border-radius: 24px;
    padding: 8px 16px;
  }

  .longer-time-info {
    font-weight: $bold;
    font-size: 16px;
    line-height: 1.4;
    color: $orange;
  }

  .title {
    font-size: 28px;
  }
}
