@use "./src/scss/global.scss" as *;

.unsupported-browser {
  background: $gradient-1;
  padding: 80px 117px;
  border-radius: 12px;

  *:not(button) {
    max-width: 100%;
  }

  .modal {
    padding: 0px 15px;
  }

  .icons-wrapper {
    width: 100%;
    img {
      width: 60px;
      height: 60px;
    }
  }
}
