@use "./src/scss/global.scss" as *;

.sales-stages {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  height: 20px;
  background: rgba(141, 141, 139, 0.1);
  border-radius: 33px;
}
