@use "./src/scss/global.scss" as *;

.hero {
  height: max-content;
  width: 100%;
  padding-top: 202px;
  position: relative;

  .video {
    border-radius: 6px;
    border: unset;
  }

  .head-description {
    max-width: 90%;
  }

  * {
    z-index: 4;
  }

  .flare {
    position: absolute;
    right: -2706px;
    top: -2295px;
    z-index: 0;
  }
}
