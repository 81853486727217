@use "@scss/global.scss" as *;

.walletAddress {
  width: max-content;
  height: max-content;
  padding: 2px 2px 2px 12px;
  background-color: $black;
  border-radius: 12px;
}

.contentWallet {
  font-weight: $light;
  font-size: 12px;
  line-height: 78%;
  letter-spacing: 0.02em;
}

