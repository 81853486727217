@use "@scss/global.scss" as *;

.connect-metamask-modal {
  padding: 120px 240px;
  width: 790px;
  text-align: center;
  position: relative;

  .stack {
    height: 100%;
    width: 100%;
  }
  .title {
    margin-bottom: 36px;
  }

  .link {
    margin-left: 5px;
    margin-right: 5px;
    color: $link;
    text-decoration: underline;
  }

  .additional {
    max-width: 460px;
  }

  .close-icon {
    width: max-content;
    height: max-content;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 25px;
  }
}
