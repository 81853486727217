$primary: #e7a525;
$primary-hover: #d09521;

$white: #f6f6f6;
$black: #1b1a17;
$black-2: #232323;
$blackest: #161616;

$white-0: #bbbbba;
$white-1: #a4a3a2;
$white-2: #8d8d8b;
$white-3: #32312f;
$white-4: #dbdada;
$white-5: #3d3c3a;
$white-6: #d9d9d9;

$white-2-hover: #80807d;

$orange: #ffa800;

$success: #0d9534;
$danger: #f03535;
$warning: #ffb21d;

$link: #1e55e2;

$gradient-1: radial-gradient(
    163.41% 163.41% at 124.71% -49.89%,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  )
  #2f2f2f;
$gradient-2: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #e7a525;
$gradient-3: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #32312f;
$gradient-4: linear-gradient(270deg, #ffa800 -48.4%, #4e3300 100%);
$gradient-5: radial-gradient(100% 306.25% at 0% 100%, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%),
  radial-gradient(100% 468.56% at 100% 0%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%), #e7a525;
$gradient-6: linear-gradient(225deg, #3b3b3b, #2a2a2a);
$gradient-7: linear-gradient(43.17deg, #262626 48.29%, #2c2a24 100%);

$shadow-1: 0px 7px 80px rgba(255, 178, 29, 0.07), 0px 4.53704px 46.8519px rgba(255, 178, 29, 0.0531481),
  0px 2.6963px 25.4815px rgba(255, 178, 29, 0.0425185), 0px 1.4px 13px rgba(255, 178, 29, 0.035),
  0px 0.57037px 6.51852px rgba(255, 178, 29, 0.0274815), 0px 0.12963px 3.14815px rgba(255, 178, 29, 0.0168519);

$shadow-2: 0px 24px 80px rgba(0, 0, 0, 0.07), 0px 14.1312px 41.728px rgba(0, 0, 0, 0.050624),
  0px 7.2576px 19.584px rgba(0, 0, 0, 0.039312), 0px 2.9184px 8.576px rgba(0, 0, 0, 0.030688),
  0px 0.6528px 3.712px rgba(0, 0, 0, 0.019376);

$shadow-3: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0531481),
  0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185), 0px 20px 13px rgba(0, 0, 0, 0.035),
  0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519);

$shadow-4: 0px 0px 12px rgba(231, 165, 37, 0.45), 0px 0px 7.02778px rgba(231, 165, 37, 0.341667),
  0px 0px 3.82222px rgba(231, 165, 37, 0.273333), 0px 0px 1.95px rgba(231, 165, 37, 0.225),
  0px 0px 0.977778px rgba(231, 165, 37, 0.176667), 0px 0px 0.472222px rgba(231, 165, 37, 0.108333);

$screen-sizes: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1110px,
  xxl: 1400px
);

$xs: map-get($screen-sizes, xs);
$sm: map-get($screen-sizes, sm);
$md: map-get($screen-sizes, md);
$lg: map-get($screen-sizes, lg);
$xl: map-get($screen-sizes, xl);
$xxl: map-get($screen-sizes, xxl);

$size-dx: 8px;

$size-1: $size-dx;
$size-2: $size-dx * 2;
$size-3: $size-dx * 3;
$size-4: $size-dx * 4;
$size-5: $size-dx * 5;
$size-6: $size-dx * 6;
$size-7: $size-dx * 7;
$size-8: $size-dx * 8;
$size-9: $size-dx * 9;
$size-10: $size-dx * 10;

$size-variants: (
  1: $size-1,
  2: $size-2,
  3: $size-3,
  4: $size-4,
  5: $size-5,
  6: $size-6,
  7: $size-7,
  8: $size-8,
  9: $size-9,
  10: $size-10
);

$order-variants: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20;

$percentage-variants: (
  100: 100%,
  90: 90%,
  80: 80%,
  70: 70%,
  60: 60%,
  50: 50%,
  40: 40%,
  30: 30%,
  20: 20%,
  10: 10%,
  0: 0%
);

$columns: (
  1: calc((1 / 12) * 100%),
  2: calc((2 / 12) * 100%),
  3: calc((3 / 12) * 100%),
  4: calc((4 / 12) * 100%),
  5: calc((5 / 12) * 100%),
  6: calc((6 / 12) * 100%),
  7: calc((7 / 12) * 100%),
  8: calc((8 / 12) * 100%),
  9: calc((9 / 12) * 100%),
  10: calc((10 / 12) * 100%),
  11: calc((11 / 12) * 100%),
  12: calc((12 / 12) * 100%)
);

$display-variants: (
  b: block,
  f: flex,
  n: none
);
$ultra-light: 300;
$light: 400;
$regular: 500;
$medium: 600;
$bold: 700;
