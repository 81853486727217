@use "@scss/global.scss" as *;

$triangle-right: 12px;

.wallet {
  position: relative;
  height: 18px;

  .balance {
    font-size: 12px;
    line-height: 78%;

    .error {
      color: $danger;
    }
  }

  .article {
    top: 34px;
    right: 26px;
    position: absolute;
    width: max-content;
    max-width: 250px;
    background-color: $danger;
    border-radius: 8px;
    padding: 12px 16px;

    &:before,
    &.article:after {
      content: "";
      display: block;
      width: max-content;
      height: max-content;
      position: absolute;
      bottom: 100%;
    }

    &:after {
      right: 8px;
      border: 10px solid $danger;
      top: -6px;
      transform: rotate(45deg) scale(0.8);
      border-color: $danger;
    }
  }

  .currency {
    font-size: 12px;
    color: $white-2;
  }
}
