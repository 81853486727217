@use "./src/scss/global.scss" as *;

.order-history {
  .table-title {
    text-transform: uppercase;
  }

  hr {
    opacity: 0.15;
  }
}
