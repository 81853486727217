@use "./src/scss/global.scss" as *;

.gradient-border-wrapper {
  position: relative;
  background: linear-gradient(225deg, #646464, #262626);
  width: 100%;
  height: 100%;
  min-height: 200px;
  min-width: 300px;
  padding: 1px;
  z-index: 1;

  .gradient-border {
    width: max-content;
    height: max-content;
    max-width: 100%;
    max-height: 100%;
    border-radius: inherit;
    border: 1px solid transparent;

    &.gradient-5 {
      background: $gradient-1;
    }
    &.gradient-6 {
      background: $gradient-6;
    }
  }

  &.with-box-shadow {
    box-shadow: $shadow-2;
  }

  &::before {
    background: linear-gradient(225deg, $primary, $primary);
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: opacity 0.2s;
    opacity: 0;
    border-radius: 8px;
  }
  &.hover::before {
    opacity: 1;
  }
}
