@use "@scss/global.scss" as *;

.access-page {
  padding-top: 130px;
  height: 100%;
  width: 100%;
  margin: auto;
  max-width: 865px;
  text-align: center;

  .title {
    max-width: 720px;
  }

  .bg-radial-gradient {
    position: absolute;
    z-index: 0;
    right: 0px;
    top: 0px;
    width: 1200px;
    height: 100%;
  }

  .stack {
    position: relative;
    z-index: 1;
  }

  .additional {
    max-width: 350px;
  }

  form {
    width: 100%;
  }
}
