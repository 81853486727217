@use "./src/scss/global.scss" as *;

$base-card-height: 525px;
$primary-card-height-bonus: 60px;

.presale-card {
  transition: all 0.2s;
  flex: 1;
  background: $gradient-1;
  box-shadow: $shadow-3;
  border-radius: 9px;
  position: relative;

  &-inner {
    width: 100%;
    height: 100%;
    position: relative;

    &.disabled {
      opacity: 0.24;
    }
  }

  &.primary {
    height: $base-card-height + $primary-card-height-bonus;

    .best-price {
      margin-top: 48px;
    }

    .prices {
      margin-top: 41px;
    }
  }

  &.secondary {
    height: $base-card-height;

    .prices {
      margin-top: 85px;
    }
  }

  .best-price {
    text-transform: uppercase;
    letter-spacing: 2.87px;
  }
  .prices {
    position: relative;
    margin-bottom: 40px;

    .old-price,
    .discount-price {
      position: relative;
    }

    .discount-price {
      margin-top: 56px;
      transform: translateX(13px);
    }

    .currency {
      font-size: 33px;
      align-self: flex-start;
      line-height: 1;
      transform: translate(-3px, -14px);
      font-weight: $ultra-light;
    }
    .value {
      line-height: 0.7;
    }
    .ico {
      align-self: flex-end;
      margin-left: 4px;
      line-height: 1;
      max-width: 52px;
      text-align: start;
      font-weight: $ultra-light;
    }

    .strikethrough {
      @include absolute-center();
    }

    .arrow {
      @include absolute-center();
      left: unset;
      right: 20px;
    }
  }

  .g2n-result {
    margin-bottom: 32px;
  }

  .c2a {
    margin-bottom: 29px;
    max-width: 228px;

    .bolder {
      font-weight: 700 !important;
    }
  }

  .vesting.hoverable {
    transition: color 0.1s ease-in-out;
    position: relative;
    margin-bottom: 14px;
    text-align: left;
    &:hover {
      .underline {
        color: $white;
        cursor: default;
      }
    }
  }

  .full-size {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .motion-extra-tokens {
    position: absolute;
    z-index: -1;
  }

  &.disabled {
    pointer-events: none;
    background: unset;
    box-shadow: unset;
  }
}
