@use "./src/scss/global.scss" as *;

.mailing-form {
  width: 539px !important;
  text-align: center;
  margin: auto;

  .title {
    line-height: 1.3;
  }

  .description {
    line-height: 1.6;
  }

  .input {
    width: 100%;
  }

  .text-area {
    width: 100%;

    textarea {
      min-height: 170px;
      font-size: 16px;
    }
  }
}
