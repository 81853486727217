@use "./src/scss/global.scss" as *;

.timeline {
  margin-bottom: 100px;
  padding-top: 132px;
  position: relative;
  z-index: 5;

  .title {
    font-size: 28px;
  }
}
