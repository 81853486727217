@use "./src/scss/global.scss" as *;

.footer {
  height: 60px;
  background: lighten($black, 1%);

  .inner-footer {
    padding: 0px 15px;
    width: 1110px;
    height: 100%;
    margin: auto;
  }
}
