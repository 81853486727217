@use "./src/scss/global.scss" as *;

.done-transaction-modal {
  width: 790px;
  .close-button {
    position: absolute;
    width: max-content;
    right: 0px;
    top: 0px;
    padding: 25px;
  }

  .done-modal {
    padding: 120px 102px;

    .hover {
      transition: color 0.2s ease-in-out;

      &:hover {
        color: darken($primary, 10%);
      }
    }
  }
}
