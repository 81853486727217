.outer-modal {
  position: relative;

  .close-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 25px;
    width: max-content;
    height: max-content;
  }

  .how-to-buy-modal {
    padding: 87px 147px;
    .title {
      font-size: 28px;
      margin-bottom: 30px;
    }

    .video {
      width: 500px;
      height: max-content;
      min-height: 278px;
      border: unset;
      border-radius: 8px;
    }
  }
}
