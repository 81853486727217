@use "@scss/global.scss" as *;

.not-found {
  height: 100vh;
  position: relative;
  overflow: hidden;

  .bg-radial-gradient-top {
    position: absolute;
    z-index: 0;
    right: 0px;
    top: 0px;
    width: 1200px;
    height: 100%;
  }

  &-content {
    position: relative;
    z-index: 1;

    .title {
      font-size: 28px;
      margin-bottom: 36px;
    }
  }

  .bg-radial-gradient-bottom {
    position: absolute;
    z-index: 0;

    width: 2665px;
    height: 2606px;
    left: -1583px;
    top: -389px;
  }
}
